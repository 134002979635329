import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  BannerSmall,
  TextBox,
  BlogSlider,
  BannerLinks,
  Breadcrumbs,
  Appointment
} from '../../components'

import cards from '../../components/BlogSlider/DefaultCards'
import boxes from '../../components/BannerLinks/DefaultBox'

import BannerImage from '../../assets/projektuebersicht.jpg'
import Georg from '../../assets/georg_neu.png'

import { generateBreadcrumbsLinks } from '../../utils/index'

const DsgvoEinfach = () => {
  const links = generateBreadcrumbsLinks('/blog/dsgvo-einfach', 'DSGVO Einfach')
  return (
    <Layout>
      <Helmet
        title="DSGVO und einfach!"
        meta={[
          {
            name: 'description',
            content:
              'Bei Fragen zu DSGVO und Website können Sie sich gerne bei uns melden. Für die Website haben wir für Sie als Hilfe eine Checkliste zusammengestellt. '
          },
          {
            name: 'keywords',
            content:
              'DSGVO holzweg, Blog holzweg, Innsbruck, holzweg, Blog, DSGVO, Datenschutz, Datenschutz Website, DSGVO holzweg, DSGVO Checkliste'
          },
          {
            property: 'og:description',
            content:
              'Bei Fragen zu DSGVO und Website können Sie sich gerne bei uns melden. Für die Website haben wir für Sie als Hilfe eine Checkliste zusammengestellt. '
          },
          {
            property: 'og:image',
            content: 'https://www.holzweg.com/static/projektuebersicht-3b0d0cca7e33df5e55e7b68d779cc633.jpg'
          }
        ]}
      />
      <BannerSmall
        image={BannerImage}
        title={
          <span>
            Datenschutz<br/>
            Checkliste
          </span>
        }
      />
      <Breadcrumbs
        backLinkAlias=' '
        links={links}
      />
      <TextBox
        title="DSGVO und einfach!"
        text={
          <span>
            <p><b>Auch wie so viele noch unsicher zum Thema DSGVO, Website und einfach?</b></p>
            <p>Für DSGVO für Websites haben wir für Sie als Hilfe eine Checkliste zusammengestellt. Dort finden sie nach Themen getrennt Punkte aufgelistet.</p>
            <p>
              <a className="button button--color-black" href="/downloads/DSGVO-Checkliste-holzweg.pdf" target="_blank">
                Download DSGVO Checkliste als PDF
              </a><br/>
            </p>
            <p>E-Mail Marketing ist eigentlich nicht in der DSGVO geregelt sondern fällt seit 2003 in das Telekommunikationsgesetz(TKG § 107), welches letztmalig 2011 überarbeitet wurde. Durch DSGVO wurden alle Unternehmen aber sensibilisiert, was die Nutzung der gesammelten Informationen über ihre Kunden angeht.
            </p>
            <h3>Häufige Fragen die dazu auftauchen:</h3>
              <ul>
                <li>Wie gehe ich mit den bestehenden Kundendaten um? </li>
                <li>Was muss ich zukünftig bei Emailaktionen beachten? </li>
                <li>Was tun wenn ich mit Partnern zu diesem Thema  zusammenarbeite?</li>
              </ul>
            <p>Bei Fragen zu DSGVO und Website können Sie sich gerne bei uns melden! Wenn möglich können wir dazu telefonisch eine kurze Ist-Standsanalyse machen bzw. einen Termin bei Ihnen vor Ort vereinbaren.
            </p>
          </span>
        }
      />
      <Appointment image={Georg} />
      <BlogSlider cards={cards} singleRow={true} title="Weitere Blogeinträge"/>
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default DsgvoEinfach
